.swiper {
  .swiper-pagination-bullets {
    bottom: -0.2rem;
    .swiper-pagination-bullet {
      transition: $transition-base;
      cursor: pointer;
      opacity: 0.3;
      border-radius: 5rem;
      background-color: $primaryWhite;
      width: 5.2rem;
      height: 0.5rem;
      margin: 0 0.5rem;
      &-active {
        background-color: $primaryOrange;
        opacity: 1;
      }
    }
    &.swiper-pagination--blue {
      .swiper-pagination-bullet {
        background-color: $lightBlue;
        &-active {
          background-color: $primaryOrange;
        }
      }
    }
    &.swiper-pagination--with-nav {
      display: flex;
      flex-wrap: nowrap;
      bottom: 0.6rem;
      max-width: 47.8rem;
      padding: 0 5rem;
      margin: 0 auto;
      right: 0;
      @include tabletP {
        max-width: 76.8rem;
        padding: 0 5.3rem;
      }
      @include tabletL {
        max-width: 102.4rem;
      }
      @include desk {
        max-width: 122rem;
      }
      .swiper-pagination-bullet {
        height: 0.3rem;
        width: 100%;
        opacity: 1;
      }
    }
  }
  &--with-fade {
    padding-right: 8rem;
    margin-right: -2.5rem;
    @include tabletP {
      margin-right: -3rem;
      padding-right: 24.3rem;
    }
    @include tabletL {
      margin-right: 0;
    }
    @include desk {
      padding-right: 20.6rem;
    }
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 8rem;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.526043) 54.69%, rgba(255, 255, 255, 0) 100%);
      z-index: 1;
    }
    .swiper-pagination-bullets.swiper-pagination--with-nav {
      right: 2.5rem;
      width: auto;
      @include tabletP {
        right: 3rem;
      }
      @include tabletL {
        right: 0;
      }
    }
    .swiper-buttons {
      right: 2.5rem;
      @include tabletP {
        right: 3rem;
      }
      @include tabletL {
        right: 0;
      }
    }
  }
}
.swiper-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 47.8rem;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @include tabletP {
    max-width: 76.8rem;
  }
  @include tabletL {
    max-width: 102.4rem;
  }
  @include desk {
    max-width: 122rem;
  }
  .btn--link {
    z-index: 11;
    i {
      font-size: 1.5rem;
      margin: 0;
    }
    &:hover i::before {
      color: $primaryOrange;
      @include tabletP {
        color: $primaryYellow;
      }
    }
    &.swiper-button-disabled {
      pointer-events: none;
      i::before {
        opacity: 0.5;
      }
    }
  }
}
.swiper-fade .swiper-slide {
  opacity: 0!important;
  height: auto;
  &.swiper-slide-duplicate-active, &.swiper-slide-visible {
    opacity: 1!important;
  }
}

