$primaryWhite: #ffffff;
$primaryBlack: #000000;
$primaryYellow: #FFC63B;
$primaryBlue: #58A5DB;
$primaryGreen: #4CA59B;
$primaryOrange: #DD4026;
$primaryPurple: #C51D5D;
$primaryRed: #FF0000;
$lightYellow: #FFF9EB;
$lightBlue: #EEF6FB;
$lightGreen: #EDF6F5;
$lightOrange: #FCECE9;
$lightPurple: #F9E8EF;
$txtHeader: #333333;
$txtParagraph: #555555;
$txtFormEpt: #C6C6C6;
$primaryBgrd: linear-gradient(81.05deg, #C51D5D -2.18%, #DD4026 117.68%);
$primaryBorder: #DEDEDE;
$navBoxShadow: 0px 14px 24px rgba(0, 0, 0, 0.06);
$accept: #8DDB72;
$errorRed: #F13E3E;
$imgShadow: #0000000f;
