.newsletter {
  position: relative;
  padding: 7rem 0 4.8rem;
  @include tabletP {
    padding: 9.2rem 0 8.4rem;
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center center;
      object-position: center center;
    }
  }
  &__content {
    position: relative;
    z-index: 1;
    @include desk {
      max-width: 100.4rem;
      margin: 0 auto;
    }
  }
  h2 {
    margin-bottom: 2rem;
  }
  h6 {
    @include desk {
      width: 63%;
    }
  }
  .form {
    margin-top: 4.4rem;
    @include tabletP {
      margin-top: 3.4rem;
    }
    @include desk {
      margin-top: 4.4rem;
    }
    &__row {
      @include tabletP {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 2rem;
        @include desk {
          gap: 3rem;
        }
      }
    }
    &__group {
      margin-bottom: 1.9rem;
      @include desk {
        flex-basis: 40%;
      }
      &--checkbox {
        margin-top: 4.5rem;
        margin-bottom: 2rem;
        @include tabletP {
          margin-top: 1.5rem;
        }
        @include desk {
          flex-basis: 100%;
          margin-top: 2.5rem;
        }
        .form__label {
          display: inline-block;
        }
        &.form__group--error {
          margin-bottom: 0;
        }
      }
    }
    &__actions {
      margin-top: 4rem;
      @include tabletP {
        flex-basis: 32%;
        margin-top: 2rem;
      }
      @include desk {
        flex-basis: 19%;
      }
    }
  }
}
