// grid
$columnCount: 18;

$gridGutterMobile: 0.4rem;
$gridGutterTabletP: 0.8rem;
$gridGutterTabletL: 0.8rem;
$gridGutterDesk: 5rem;

// container padding
$gridSpacerMobile: 2.5rem;
$gridSpacerTabletP: 3rem;
$gridSpacerTabletL: 3rem;
$gridSpacerDesk: 5rem;

// breakpoints
$breakpointTabletP: 768px; // portrait
$breakpointTabletL: 1024px; // landscapee
$breakpointDesk: 1366px;
$breakpointDeskHD: 1920px;

// fonts
$fontFamilyPrimary: 'Poppins', sans-serif;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$letterSpacing: 0.1em;

// transition
$transition-base: 0.3s ease-in-out;

// transition
$shadowPrimary: 14px 14px 100px rgba(0, 0, 0, 0.7);
$shadowSecondary: 14px 14px 100px rgba(0, 0, 0, 0.1);

$tabletTextWidth: 45.2rem;
$deskTextWidth: 48.9rem;
$deskLTextWidth: 71rem;
