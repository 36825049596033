.testimonial {
  position: relative;
  text-align: center;
  color: $txtParagraph;
  margin-top: 3.6rem;
  height: calc(100% - 3.6rem);
  &__content {
    background-color: $primaryWhite;
    box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.06);
    border-radius: 4.5rem;
    display: flex;
    flex-direction: column;
    padding: 6.7rem 2rem 4rem;
    justify-content: center;
    height: calc(100% - 10.7rem);
    @include desk {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  &__quotation-marks {
    background-color: $primaryYellow;
    display: block;
    border-radius: 6rem;
    width: 8.5rem;
    height: 8.5rem;
    margin: auto;
    position: absolute;
    top: -3.6rem;
    left: 0;
    right: 0;
    font-size: 2.5rem;
    line-height: 8.5rem;
    i {
      margin: 0 2px;
    }
  }
  &__name {
    font-weight: $fontWeightSemiBold;
    margin-top: 2.6rem;
  }
}
