.footer {
  position: relative;
  display: block;
  padding: 5rem 0 2rem;
  background-color: $primaryWhite;
  z-index: 1;
  &__wrapper {
    @include tabletP {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &__logo {
    display: block;
    max-width: 13.5rem;
    margin: 0 auto;
    @include tabletP {
      margin: 0;
    }
    @include desk {
      max-width: 21.2rem;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &__row {
    @include tabletP {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 3rem 0;
    }
    .footer__links {
      @include tabletP {
        align-items: flex-start;
        justify-content: flex-start;
      }
      &__item {
        @include tabletP {
          text-align: left;
          margin: 0 0 1rem 0;
        }
      }
    }
  }
  &__links {
    margin-top: 2.4rem;
    width: 21.8rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    @include tabletP {
      margin: 2rem 0 2rem 0;
      width: auto;
    }
    @include desk {
      margin: 3rem 0 3rem 0;
    }
    &__item {
      width: 100%;
      text-align: center;
      @include tabletP {
        width: auto;
        margin-right: 0.5rem;
      }
      @include desk {
        margin-right: 1rem;
      }
      &:last-child {
        margin-right: 0
      }
    }
    a:hover {
      color: $primaryOrange;
    }
  }
  &__socials {
    margin-top: 3.1rem;
    @include tabletP {
      margin-top: 0.4rem;
    }
  }
  &__copyright {
    padding-top: 1.9rem;
    margin-top: 4.7rem;
    text-align: center;
    border-top: 0.1rem solid $primaryBorder;
    @include tabletL {
      text-align: left;
    }
    @include desk {
      margin-top: 4.6rem;
    }
    &--dev {
      margin-top: 2rem;
      @include tabletL {
        text-align: right;
        position: absolute;
        bottom: 2rem;
        right: 0;
        left: 0;
      }
    }
  }
}
