.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
  transition: $transition-base;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(3, 3, 3, 0.3);
    transition: $transition-base;
  }
  &--hide {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  &__container {
    padding: 4rem 0;
    background-color: $primaryWhite;
    position: relative;
    z-index: 1;
    @include tabletP {
      padding: 5rem 0;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    @include tabletP {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
  .icon-cookies {
    font-size: 3.3rem;
  }
  &__text {
    margin-top: 2.7rem;
    @include tabletP {
      margin-top: 0;
      padding-left: 2.7rem;
      width: calc(100% - 3.4rem);
    }
    @include tabletL {
      width: calc(100% - 40.4rem);
    }
    @include desk {
      padding-right: 11.5rem;
    }
    h4 {
      margin-bottom: 1.4rem;
    }
  }
  &__action {
    margin-top: 1.9rem;
    @include tabletP {
      margin-top: 1.4rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    @include tabletL {
      width: 37rem;
      margin-top: 1.9rem;
    }
    .btn {
      width: 100%;
      margin-top: 3rem;
      @include tabletP {
        width: auto;
        margin-left: 3.8rem;
      }
      @include tabletL {
        margin-top: 0;
      }
      &--link {
        @include tabletP {
          order: -1;
        }
      }
    }
  }
}
