@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

h1, h2, h3, h4 {
  font-family: $fontFamilyPrimary;
  font-weight: $fontWeightBold;
}

h5, h6 {
  font-weight: $fontWeightRegular;
}

.font-weight-bold {
  font-weight: $fontWeightBold;
}

.font-weight-semi-bold {
  font-weight: $fontWeightSemiBold;
}

h1, .h1 {
  font-size: 3.9rem;
  line-height: 4.8rem;
  @include tabletP {
    font-size: 4.2rem;
    line-height: 5.1rem;
  }
  @include desk {
    font-size: 4.2rem;
    line-height: 5.1rem;
  }
}

h2 {
  font-size: 2.8rem;
  line-height: 4.4rem;
  @include tabletP {
    font-size: 3.3rem;
    line-height: 4.8rem;
  }
  @include desk {
    font-size: 3.3rem;
    line-height: 4.8rem;
  }
}

h3 {
  font-size: 2.6rem;
  line-height: 3.8rem;
}

h4 {
  font-size: 2rem;
  line-height: 2.6rem;
}

h5 {
  font-size: 1.8rem;
  line-height: 2.7rem;
}

h6 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.paragraph-m {
  font-size: 1.4rem;
  line-height: 2.3rem;
}

.paragraph-l {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

small, .paragraph-s {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.info-bold {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: $fontWeightBold;
}

strong {
  font-weight: $fontWeightBold;
}

p {
  a {
    transition: $transition-base;
    color: $primaryOrange;
    &:hover {
      color: $primaryYellow ;
    }
  }
}
