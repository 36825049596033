.info-box {
  display: flex;
  justify-content: center;
  @include tabletP {
    justify-content: flex-start;
  }
  &__item {
    border: 0.2rem solid $primaryWhite;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $fontWeightBold;
    color: $primaryWhite;
    padding: 0.8rem 2.8rem;
    &--white {
      background-color: $primaryWhite;
      color: $primaryOrange;
      text-transform: uppercase;
    }
    &--orange {
      border: 0.1rem solid $primaryOrange;
      padding: 0.4rem 1.1rem;
      color: $primaryOrange;
    }
  }
  &--listing {
    .info-box {
      &__item {
        border: 0.1rem solid $primaryWhite;
        padding: 0.4rem 1.1rem;
      }
    }
  }
}
