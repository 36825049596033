@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?ijvx2i');
  src:  url('../../fonts/icomoon.eot?ijvx2i#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?ijvx2i') format('truetype'),
    url('../../fonts/icomoon.woff?ijvx2i') format('woff'),
    url('../../fonts/icomoon.svg?ijvx2i#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  // font-display: block;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-spotify:before {
  content: "\e924";
}
.icon-log-out:before {
  content: "\e923";
  color: #c51d5d;
}
.icon-plus:before {
  content: "\e922";
  color: #dd4026;
}
.icon-training:before {
  content: "\e921";
  color: #c51d5d;
}
.icon-check-o:before {
  content: "\e91e";
  color: #c51d5d;
}
.icon-sort:before {
  content: "\e91d";
  color: #dd4026;
}
.icon-info:before {
  content: "\e91f";
  color: #555;
}
.icon-toggle-password:before {
  content: "\e914";
  color: #dedede;
}
.icon-film:before {
  content: "\e90f";
  color: #c51d5d;
}
.icon-time-left:before {
  content: "\e910";
  color: #c51d5d;
}
.icon-facebook-outline:before {
  content: "\e911";
  color: #c51d5d;
}
.icon-question:before {
  content: "\e912";
  color: #c51d5d;
}
.icon-my-data:before {
  content: "\e913";
  color: #c51d5d;
}
.icon-setting:before {
  content: "\e920";
  color: #c51d5d;
}
.icon-selection:before {
  content: "\e915";
  color: #c51d5d;
}
.icon-money:before {
  content: "\e916";
  color: #c51d5d;
}
.icon-pin:before {
  content: "\e917";
  color: #c51d5d;
}
.icon-calendar:before {
  content: "\e918";
  color: #c51d5d;
}
.icon-calendar-empty:before {
  content: "\e919";
  color: #c51d5d;
}
.icon-clock:before {
  content: "\e91a";
  color: #c51d5d;
}
.icon-star:before {
  content: "\e91b";
  color: #c51d5d;
}
.icon-presentation:before {
  content: "\e91c";
  color: #c51d5d;
}
.icon-cookies:before {
  content: "\e906";
  color: #c51d5d;
}
.icon-play .path1:before {
  content: "\e90c";
  color: rgb(221, 64, 38);
}
.icon-play .path2:before {
  content: "\e90d";
  margin-left: -0.9833984375em;
  color: rgb(255, 255, 255);
}
.icon-close-ico:before {
  content: "\e90b";
  color: #dd4026;
}
.icon-instagram:before {
  content: "\e908";
  color: #dd4026;
}
.icon-facebook:before {
  content: "\e909";
  color: #dd4026;
}
.icon-youtube:before {
  content: "\e90a";
  color: #dd4026;
}
.icon-arrow-big:before {
  content: "\e900";
  color: #dd4026;
}
.icon-arrow:before {
  content: "\e901";
  color: #dd4026;
}
.icon-close-small:before {
  content: "\e902";
  color: #dd4026;
}
.icon-check:before {
  content: "\e903";
  color: #dd4026;
}
.icon-search:before {
  content: "\e904";
  color: #dd4026;
}
.icon-hamburger:before {
  content: "\e905";
  color: #dd4026;
}
.icon-account:before {
  content: "\e907";
  color: #dd4026;
}
.icon-quote:before {
  content: "\e90e";
  color: #fff;
}
