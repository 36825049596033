@mixin tabletP {
  @media (min-width: #{$breakpointTabletP}) {
    @content;
  }
}

@mixin tabletL {
  @media (min-width: #{$breakpointTabletL}) {
    @content;
  }
}

@mixin desk {
  @media (min-width: #{$breakpointDesk}) {
    @content;
  }
}

@mixin deskHD {
  @media (min-width: #{$breakpointDeskHD}) {
    @content;
  }
}

@mixin tabletLHorizontal {
  @media (min-width: #{$breakpointTabletL}) and (max-height: 865px) {
    @content;
  }
}

@mixin deskHorizontal {
  @media (min-width: #{$breakpointDesk}) and (max-height: 865px) {
    @content;
  }
}
