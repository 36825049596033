.traning-form {
  position: absolute;
  bottom: 7.1rem;
  left: 0;
  right: 0;
  z-index: 1;
  @include tabletP {
    bottom: 9rem;
  }
  @include tabletL {
    width: 491px;
    right: calc((100vw - 99.4rem)/2);
    left: auto;
    bottom: 9rem;
  }
  @include desk {
    right: calc((100vw - 122rem)/2);
  }
  .form {
    padding: 4rem 3rem;
    background-color: $primaryWhite;
    box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.06);
    border-radius: 4.5rem;
    @include tabletP {
      padding: 4.5rem;
    }
  }
  h4 {
    text-align: center;
    margin-bottom: 3rem;
    @include tabletP {
      margin-bottom: 2.8rem;
    }
  }
}
