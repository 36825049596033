.bg-dark {
  background: $primaryBlack;
}

.offset-scroll {
  padding-top: 8.7rem;
  margin-top: -8.7rem;
  @include tabletL {
    padding-top: 9.2rem;
    margin-top: -9.2rem;
  }
}

.title {
  text-align: center;
  @include tabletP {
    text-align: left;
  }
  @include desk {
    text-align: center;
  }
  &--white {
    color: $primaryWhite;
    @include tabletP {
      text-align: center;
    }
  }
  &--center {
    @include tabletP {
      text-align: center;
    }
  }
  &--left {
    text-align: left;
  }
}
.video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.3%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  &--information {
    padding-top: 59.8%;
    @include tabletL {
      padding-top: 30%;
      max-width: 59.5rem;
    }
  }
  &--img-banner {
    border-radius: 2.5rem;
    border: 0.2rem solid $primaryWhite;
    overflow: hidden;
    @include tabletP {
      padding-top: 55%;
    }
    @include tabletL {
      padding-top: 68%;
    }
    @include desk {
      padding-top: 55%;
    }
  }
  &--all {
    height: 41.8rem;
    padding-top: 0;
    @include tabletP {
      height: 42.2rem;
    }
    @include tabletL {
      height: 100%;
    }
  }
}

.display-none {
  display: none!important;
}
