.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $transition-base;
  cursor: initial;
  background-color: $primaryWhite;
  width: 100%;
  max-width: 47.8rem;
  @include tabletP {
    max-width: 70.8rem;
    border-radius: 4.5rem;
  }
  @include tabletL {
    max-width: 99.6rem;
  }
  &--sm {
    width: 32.5rem;
    border-radius: 4.5rem;
    padding: 4.5rem;
    @include tabletP {
      width: 100%;
      max-width: 59.5rem;
    }
  }
  &--active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  &__content {
    @include tabletP {
      display: flex;
      flex-wrap: nowrap;
    }
  }
  &__sidebar {
    background-color: $lightBlue;
    padding: 5rem;
    @include tabletP {
      padding: 3rem;
      border-top-left-radius: 45px;
      border-bottom-left-radius: 45px;
    }
    @include desk {
      padding: 4.5rem 4.2rem 4.5rem 4.5rem;
    }
  }
  &__text {
    padding: 2.5rem;
    text-align: left;
    @include tabletP {
      padding: 4.4rem;
    }
    @include desk {
      padding: 4.5rem 9.4rem 4.5rem 7.2rem;
    }
    p + p {
      margin-top: 2.3rem;
    }
  }
  &__close-btn {
    background-color: $primaryWhite;
    border-radius: 2.2rem;
    width: 4.4rem;
    height: 4.4rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 1.8rem;
    line-height: 4.6rem;
    cursor: pointer;
    @include tabletP {
      position: fixed;
      top: -1rem;
      right:-1rem;
    }
    @include desk {
      top: 3rem;
      right: 3rem;
    }
  }
  .scroll-bar__item {
    max-height: 100vh;
    @include tabletP {
      max-height: calc(100vh - 13.2rem);
      border-radius: 4.5rem;
    }
  }
  .ps__rail-y {
    top: 0 !important;
    margin: 4.4rem 1rem;
  }
  .lecturer {
    max-width: 23.2rem;
    margin: 0 auto;
    @include tabletP {
      max-width: 20.2rem;
    }
    @include desk {
      display: block;
      max-width: 21.1rem;
    }
    &__img {
      height: 23.2rem;
      width: 23.2rem;
      @include tabletP {
        height: 20.2rem;
        width: 20.2rem;
      }
      @include desk {
        height: 21.1rem;
        width: 21.1rem;
      }
    }
    &__name {
      margin-top: 2.2rem;
      margin-bottom: 0;
    }
  }
  &__form {
    h3 {
      margin-bottom: 1.6rem;
    }
    .form {
      margin-top: 2.5rem;
      &__row {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        @include tabletP {
          flex-direction: row;
        }
      }
      &__cancel {
        order: 2;
        @include tabletP {
          order: 0;
        }
      }
      &__actions {
        margin: 0;
        @include tabletP {
          flex: 1 1 0;
        }
      }
    }
  }
  &--video {
    max-width: 87%;
    background-color: transparent;
    @include tabletL {
      max-width: 90rem;
    }
    @include desk {
      max-width: 100rem;
    }
    .modal {
      &__close {
        top: -2rem;
        right: -1rem;
        z-index: 1;
      }
      &__video {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        margin-bottom: 1.6rem;
        iframe {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &--address {
    padding: 3.5rem;
    @include tabletP {
      padding: 4.5rem;
    }
    .scroll-bar__item {
      border-radius: 0;
      max-height: calc(100vh - 7rem);
      @include tabletP {
        max-height: calc(100vh - 9rem);
        height: auto;
      }
      &.modal__form {
        @include tabletP {
          min-height: 64rem;
        }
      }
    }
    h3 {
      margin-bottom: 2.5rem;
    }
    .modal__close-btn {
      top: 3.3rem;
      right: 2.2rem;
      z-index: 1;
      @include tabletP {
        top: 4.3rem;
        right: 3.2rem;
      }
    }
    .form__row {
      width: 100%;
      margin-bottom: 0;
    }
    .form__group--select {
      margin-bottom: 4rem;
      width: 100%;
    }
    .account-form {
      &.scroll-bar__item {
        max-height: 68vh;
        border-radius: 0;
        @include tabletP {
          max-height: none;
          overflow: visible !important;
        }
        .ps__rail-y{
          margin-top: 13.5rem;
          margin-bottom: 0;
          @include tabletP {
            display: none;
          }
        }
      }
      .form {
        &__group {
          margin-bottom: 0.5rem;
          @include tabletP {
            margin-bottom: 2rem;
          }
          &--checkbox {
            margin-top: 1.8rem;
            @include tabletP {
              margin-top: 0;
            }
          }
        }
        &__actions {
          margin-top: 3rem;
          margin-bottom: 0;
          @include tabletP {
            margin-top: 1.5rem;
          }
          .btn--full-width {
            width: 100%;
            box-shadow: none;
          }
        }
      }
    }
    .btn--full-width {
      white-space: nowrap;
      box-shadow: none;
      i {
        top: 0;
        margin-right: 1.1rem;
      }
      &+.btn--full-width {
        margin-top: 2rem;
        @include tabletP {
          margin-top: 1.5rem;
        }
      }
    }
  }
}
