.form {
  &__group {
    margin-bottom: 2.2rem;
    position: relative;
    .form__count {
      position: absolute;
      bottom: 3.2rem;
      right: 2.2rem;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $txtFormEpt;
      @include desk {
        bottom: 3.9rem;
      }
    }
    .icon-toggle-password {
      font-size: 0.9rem;
      margin-left: -3.8rem;
      cursor: pointer;
    }
    &--error {
      .form__input, .choices[data-type*=select-one] .choices__inner {
        border-color: $primaryRed;
      }
    }
    &--checkbox {
      .form__warninig {
        position: relative;
        right: auto;
        padding-left: 4.2rem;
        margin-top: 0.5rem;
      }
      .form__checkbox {
        height: initial;
        width: initial;
        display: none;
        cursor: pointer;
        &:checked + label {
          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0.5rem;
            left: 0.7rem;
            width: 0.6rem;
            height: 0.9rem;
            border: solid $primaryWhite;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            transition: $transition-base;
          }
          &::before {
            background-color: $primaryOrange;
          }
        }
      }
      .form__label {
        text-transform: none;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: $fontWeightRegular;
        position: relative;
        margin-bottom: 0;
        padding-left: 4.2rem;
        cursor: pointer;
        a {
          color: $primaryOrange;
          text-decoration: underline;
          &:hover {
            color: $primaryYellow;
          }
        }
        &::before {
          content: '';
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid $primaryOrange;
          background-color: $primaryWhite;
          top: 0;
          left: 0;
          width: 2.1rem;
          height: 2.1rem;
          display: inline-block;
          position: absolute;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 0.5rem;
          transition: $transition-base;
        }
      }
    }
    &--disabled {
      .form {
        &__label {
          cursor: default;
          pointer-events: none;
          opacity: 0.5;
        }
        &__input {
          opacity: 0.5;
          pointer-events: none;
          &::-webkit-input-placeholder {
            color: $primaryBlack;
          }

          &::-moz-placeholder {
            opacity: 1;
            color: $primaryBlack;
          }

          &:-ms-input-placeholder {
            color: $primaryBlack;
          }
          &:hover {
            box-shadow: none;
          }
        }
      }
      .icon-toggle-password {
        cursor: default;
        pointer-events: none;
      }
    }
    &--radio {
      h5 {
        margin-bottom: 1.5rem;
      }
      input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        &:checked + label::after {
          content: '';
          display: block;
          position: absolute;
          top: 0.2rem;
          left: 0.3rem;
          width: 1.1rem;
          height: 1.1rem;
          background-color: $primaryOrange;
          border-radius: 1rem;
          @include tabletP {
            left: 0.2rem;
          }
        }
      }
      label {
        position: relative;
        cursor: pointer;
        font-size: 1rem;
        font-weight: $fontWeightRegular;
        line-height: 1.5rem;
        padding: 0 0 0 2.6rem;
        display: inline-block;
        margin-right: 1.3rem;
        text-transform: capitalize;
        &::before {
          content: '';
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid $primaryOrange;
          border-radius: 2rem;
          top: -1px;
          left: 0;
          padding: 0.75rem;
          display: inline-block;
          position: absolute;
          vertical-align: middle;
          cursor: pointer;
          @include tabletP {
            top: -1px;
            left: -1px;
          }
        }
      }
      &.form__group--checkbox {
        .form__checkbox:checked+label {
          &::before {
            background-color: $primaryWhite;
          }
          &::after {
            border-color: $primaryBorder;
          }
        }
        .form__label {
          &::before {
            border-color: $primaryBorder;
          }
        }
      }
    }
  }
  &__actions {
    margin-bottom: 0;
    margin-top: 2.9rem;
    @include tabletP {
      margin-top: 3.4rem;
    }
  }
  &__label {
    font-size: 1rem;
    line-height: 1.5rem;
    display: block;
    text-transform: uppercase;
    font-weight: $fontWeightBold;
    padding-left: 2.2rem;
    margin-bottom: 0.3rem;
    &--select-check {
      cursor: pointer;
    }
  }
  &__input {
    border: 0.1rem solid $primaryBorder;
    border-radius: 2.5rem;
    padding: 1.4rem 2.2rem;
    background-color: $primaryWhite;
    font-size: 1.4rem;
    line-height: 2.3rem;
    font-family: $fontFamilyPrimary;
    width: 100%;
    transition: $transition-base;
    color: $primaryBlack;
    &:hover {
      box-shadow: 2px 7px 16px rgba(221, 64, 38, 0.17);
    }
    &:focus {
      border-color: $primaryOrange;
    }
    &--textarea {
      position: relative;
      padding: 2.2rem;
      min-height: 23rem;
      resize: none;
    }
  }
  &__warninig {
    color: $primaryRed;
    font-size: 1rem;
    line-height: 1.5rem;
    position: absolute;
    right: 2.4rem;
    top: 0;
  }
  &__select-check {
    position: relative;
    &--active {
      .form__select-check__name {
        border-color: $primaryOrange;
        border-bottom-color: transparent;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        &::after {
          margin-top: -0.25rem;
          border-color: transparent $primaryOrange $primaryOrange transparent;
          transform: rotate(225deg) translate(-50%, -50%);
        }
      }
      .form__select-check__list {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }
    &__placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      transition: $transition-base;
      &--checked {
        color: $primaryBlack;
      }
    }
    &__name {
      min-height: 5.3rem;
      width: 100%;
      border-radius: 2.5rem;
      border: 0.1rem solid $primaryBorder;
      color: $txtParagraph;
      padding: 1.4rem 4rem 1.4rem 1.7rem;
      font-size: 1.4rem;
      line-height: 2.3rem;
      background-color: $primaryWhite;
      color: $txtFormEpt;
      cursor: pointer;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        border-color: transparent $primaryOrange $primaryOrange transparent;
        border-width: 1px;
        border-style: solid;
        right: 2.3rem;
        top: calc(50% - 5px);
        width: 0.8rem;
        height: 0.8rem;
        transform: rotate(45deg);
        transition: $transition-base;
        margin-top: -2.5px;
        pointer-events: none;
      }
    }
    &__list {
      border: 0.1rem solid $primaryOrange;
      background-color: $primaryWhite;
      border-top: none;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
      display: flex;
      flex-wrap: wrap;
      padding: 1.3rem 2.2rem;
      gap: 1.3rem;
      max-height: 19.4rem;
      position: absolute;
      top: 5.3rem;
      left: 0;
      right: 0;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      cursor: initial;
      .form__row {
        flex: 1 1 0;
        flex-basis: 100%;
        @include tabletP {
          flex-basis: calc(50% - 1.1rem);
        }
        .form__group--checkbox {
          margin-bottom: 0;
          label {
            font-size: 1.4rem;
            line-height: 2.3rem;
            display: inline-block;
          }
        }
      }
    }
  }
  &__label-info {
    display: flex;
    .icon-info {
      position: relative;
      font-size: 1.1rem;
      margin: 0.2rem 0 0 0.4rem;
      cursor: pointer;
      &::before {
        transition: $transition-base;
      }
      &:hover {
        &::before {
          color: $primaryOrange;
        }
        .form__input-info {
          opacity: 1;
        }
      }
    }
  }
  &__input-info {
    opacity: 0;
    pointer-events: none;
    background-color: $primaryWhite;
    position: absolute;
    top: -0.5rem;
    left: 2rem;
    width: 17rem;
    font-family: $fontFamilyPrimary;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.5rem 1.2rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 0.7rem 0.7rem 0.7rem 0;
    transition: $transition-base;
    z-index: 1;
  }
}
