.training {
  border-radius: 4.5rem;
  box-shadow: $navBoxShadow;
  width: 100%;
  position: relative;
  &__heading {
    position: relative;
    min-height: 26.6rem;
    h4 {
      color: $primaryWhite;
      margin-top: 1.6rem;
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    img {
      border-radius: 4.5rem 4.5rem 0 0;
    }
  }
  &__text {
    position: relative;
    padding: 4rem 0;
    z-index: 1;
    max-width: 23.5rem;
    margin: 0 auto;
    @include tabletP {
      max-width: none;
      padding: 4rem 4.5rem;
    }
  }
  &__boxes {
    display: flex;
    .info-box {
      &__item:first-child {
        margin-right: 1rem;
      }
    }
  }
  &__content {
    padding-bottom: 14.1rem;
    margin: -2.7rem auto 0;
    max-width: 23.5rem;
    z-index: 1;
    @include tabletP {
      max-width: none;
      padding: 0 4.5rem 14.1rem;
    }
  }
  &__lecturers {
    display: flex;
    position: relative;
  }
  &__lecturer,
  &__additional {
    height: 5.4rem;
    width: 5.4rem;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    flex-shrink: 0;
    overflow: hidden;
  }
  &__lecturer {
    position: relative;
    margin-right: 1.3rem;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      border: 0.2rem solid transparent;
      transition: $transition-base;
    }
    &:hover:after {
      border-color: $primaryOrange;
    }
    &--active {
      &::after {
        border-color: $primaryOrange;
      }
    }
  }
  &__additional {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primaryWhite;
  }
  &__name {
    margin-top: 1.3rem;
    display: none;
    &--active {
      display: block;
    }
    span {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    p {
      line-height: 1.7rem;
    }
  }
  &__info {
    margin-top: 2.7rem;
  }
  &__detail {
    display: flex;
    align-items: center;
    span {
      font-size: 1.8rem;
      margin-right: 1.5rem;
    }
    & + .training__detail {
      margin-top: 1.3rem;
    }
  }
  .btn {
    position: absolute;
    bottom: 4.5rem;
    left: 4.5rem;
    right: 4.5rem;
  }
  &--smaller {
    .training {
      &__heading {
        min-height: 16.6rem;
      }
      &__text {
        padding-top: 2.7rem;
      }
    }
    .btn {
      margin-top: 2.4rem;
    }
  }
  &--simple {
    background: $primaryWhite;
    .btn {
      bottom: 5.5rem;
    }
    .training {
      &__content {
        @include tabletP {
          padding: 0 4.5rem 12.1rem;
        }
      }
    }
  }
}
