.header {
  position: fixed;
  display: block;
  background-color: $primaryWhite;
  width: 100vw;
  padding: 2.6rem 0;
  z-index: 10;
  transition: $transition-base;
  box-shadow: none;
  &--fixed {
    box-shadow: $navBoxShadow;
  }
  @include tabletL {
    padding: 2.4rem 0 1.8rem;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    display: block;
    max-width: 13.6rem;
    @include desk {
      max-width: 15.8rem;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 7.8rem;
    height: 3rem;
    @include tabletP {
      width: 8.05rem;
    }
    @include tabletL {
      width: auto;
    }
    &--account {
      width: 9.4rem;
      position: relative;
      @include tabletL {
        width: auto;
      }
    }
    a {
      display: inline-block;
      margin-left: 0.1rem;
      span {
        &::before {
          transition: $transition-base;
        }
        &:hover {
          &::before {
            color: $primaryYellow;
          }
        }
      }
      @include desk {
        margin-right: 0;
      }
    }
    .icon-account,
    .icon-hamburger {
      font-size: 2.5rem;
    }
    .icon-close-ico {
      font-size: 2.8rem;
    }
  }
  &__account {
    padding-right: 1.5rem;
    display: flex;
    cursor: pointer;
    @include tabletL {
      padding-right: 0.9rem;
    }
    .icon-account:before {
      color: $primaryGreen;
      transition: $transition-base;
    }
    .icon-arrow {
      transform: rotate(180deg);
      font-size: 0.5rem;
      margin-left: 0.5rem;
      position: relative;
      top: 0.9rem;
      transition: $transition-base;
    }
    &:hover {
      .icon-account:before {
        @include tabletP {
          color: $primaryYellow;
        }
      }
    }
    &--active {
      .icon-arrow {
        transform: none;
      }
    }
  }
  &__button {
    @include tabletL {
      display: none;
    }
    .icon-hamburge {
      display: inline-block;
    }
    .icon-close-ico {
      display: none;
    }
    &--active {
      .icon-hamburger {
        display: none;
      }
      .icon-close-ico {
        display: inline-block;
      }
    }
  }
  &__nav {
    padding: 2rem;
    box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.06);
    background-color: $primaryWhite;
    border-radius: 1.6rem;
    width: 22rem;
    position: absolute;
    top: 3.2rem;
    right: 5.7rem;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    cursor: initial;
    transition: $transition-base;
    @include tabletP {
      top: 3.9rem;
      right: 5.6rem;
    }
    @include tabletL {
      right: 1rem;
    }
    &--active {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
    a.header__nav-link {
      font-weight: $fontWeightSemiBold;
      margin-bottom: 1.8rem;
      display: flex;
      align-items: center;
      color: $txtHeader;

      span {
        margin-right: 1.6rem;
        font-size: 1.8rem;
      }
      &:hover {
        color: $primaryYellow;
      }
      &--active {
        color: $primaryBlack;
      }
      &--logout {
        margin-bottom: 0;
        margin-top: 3.7rem;
        position: relative;
        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 0.1rem;
          background-color: $primaryBorder;
          position: absolute;
          top: -1.9rem;
          left: 0;
        }
      }
    }
  }
}
