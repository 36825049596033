.message {
  &__item {
    font-size: 1.2rem;
    line-height: 2.4rem;
    padding: 0.4rem 0.4rem 0.4rem 2.7rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background-color: $primaryWhite;
    border-top: 0.3rem solid transparent;
    text-align: center;
    padding: 2.3rem 0;
    z-index: 99;
    transition: $transition-base;
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
    box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.06);
    h6 {
      width: 100%;
      font-weight: $fontWeightSemiBold;
      i {
        font-size: 1.6rem;
        margin-right: 1.5rem
      }
    }
    &--success {
      border-color: $accept;
      i::before{
        color: $accept;
      }
    }
    &--error {
      border-color: $errorRed;
      i {
        font-size: 2rem;
        &::before{
          color: $errorRed;
        }
      }
    }
    &--hide {
      opacity: 0;
      transform: translateY(20px);
      pointer-events: none;
    }
  }
}
