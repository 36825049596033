.navigation {
  position: absolute;
  padding: 0.2rem 0 0.9rem;
  top: 8.66rem;
  left: 0;
  width: 100%;
  max-height: calc(100vh - 8.6rem);
  overflow: auto;
  background-color: $primaryWhite;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
  box-shadow: $navBoxShadow;
  @include tabletP {
    padding: 0.4rem 0 1rem;
  }
  @include tabletL {
    position: relative;
    top: -0.3rem;
    padding: 0 3rem;
    max-width: 100%;
    opacity: 1;
    pointer-events: all;
    box-shadow: none;
  }
  &__list {
    @include tabletL {
      display: flex;
      justify-content: flex-end;
      gap: 2.5rem;
    }
  }
  &__item {
    text-align: center;
    font-weight: $fontWeightSemiBold;
    border-radius: 5rem;
    a {
      display: block;
      padding: 1.35rem 0;
      &:hover {
        color: $primaryYellow;
      }
    }
    &--active {
      background: $primaryOrange;
      color: $primaryWhite;
      @include tabletL {
        background: none;
        color: $primaryOrange;
      }
    }
  }
  &--active {
    opacity: 1;
    pointer-events: all;
  }
  .container {
    @include tabletL {
      max-width: unset;
      padding: 0;
    }
  }
}
