.trainings-listing {
  margin-bottom: 7rem;
  @include desk {
    margin-bottom: 9rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    label {
      cursor: pointer;
      span {
        font-size: 2.6rem;
        margin-right: 0.4rem;
        vertical-align: bottom;
      }
    }
    .choices[data-type*=select-one] {
      position: absolute;
      top: 5rem;
      left: 0;
      right: 0;
      @include tabletP {
        left: auto;
        width: 36.8rem;
      }
      .choices__inner {
        display: none;
      }
      &::after {
        display: none;
      }
      .choices__list--dropdown {
        border-top: 0.1rem solid $primaryOrange;
        border-radius: 2.5rem;
        .choices__list {
          max-height: none;
        }
        .choices__item:first-of-type {
          border-top: none;
        }
      }
    }
  }
  .listing {
    display: flex;
    flex-wrap: wrap;
    @include tabletP {
      margin-top: 2rem;
      gap: 3rem;
    }
    @include desk {
      margin-top: 3rem;
    }
    &__item {
      margin-top: 5rem;
      @include tabletP {
        margin-top: 3rem;
        width: calc(50% - 1.5rem);
      }
      @include desk {
        margin-top: 2rem;
        width: calc(33.3333% - 3rem);
      }
    }
  }
  &--simple {
    background-color: $lightBlue;
    padding: 9rem 0;
    margin-bottom: 0;
    .trainings-listing {
      &__header {
        display: block;
        text-align: center;
        margin-bottom: 6rem;
      }
    }
  }
}
