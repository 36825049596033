.about {
  padding: 7rem 0;
  position: relative;
  overflow-y: hidden;
  @include tabletP {
    padding: 9rem 0;
  }
  &__text {
    position: relative;
    h6 {
      margin-top: 3.3rem;
      text-align: center;
      @include tabletP {
        margin: 3.5rem auto 0;
        max-width: 57.3rem;
      }
    }
  }
  &__map {
    width: 31.3rem;
    position: absolute;
    top: -6rem;
    right: 5rem;
    z-index: -1;
    display: none;
    @include tabletP {
      display: block;
    }
    @include tabletL {
      width: 37.5rem;
      top: -4rem;
      right: 20rem;
    }
    @include desk {
      width: 47.4rem;
      top: 1.5rem;
      right: 12.7rem;
    }
  }
  &__items {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5.9rem;
    @include tabletP {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 6.7rem;
    }
    @include desk {
      margin-top: 7.7rem;
    }
    &::before {
      position: absolute;
      content: "";
      bottom: -45.9rem;
      left: -22.7rem;
      width: 66.6rem;
      height: 66.6rem;
      border-radius: 50%;
      border: 11.5rem solid $lightGreen;
      display: none;
      @include tabletP {
        display: block;
      }
      @include desk {
        bottom: -69.1rem;
        left: -37.7rem;
        width: 102.9rem;
        height: 102.9rem;
        border: 18rem solid $lightGreen;
      }
    }
    &--no-title {
      margin-top: 0;
    }
  }
  &__item {
    &:nth-child(even) .counter__content {
      color: $primaryGreen
    }
    & + .about__item {
      margin-top: 1.9rem;
      @include tabletP {
        margin-top: 0;
      }
    }
  }
  &__link {
    text-align: center;
    margin-top: 5.9rem;
    @include desk {
      margin-top: 7rem;
    }
  }

}
