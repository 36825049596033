.socials {
  margin-top: 3.1rem;
  display: flex;
  justify-content: space-between;
  width: 14.6rem;
  @include tabletP {
    margin-top: 0.4rem;
    width: 14.8rem;
  }
  @include desk {
    width: 15rem;
  }
  span {
    font-size: 2.6rem;
    color: $primaryOrange;
    @include desk {
      font-size: 3rem;
    }
    &::before {
      transition: $transition-base;
    }
    &:hover {
      &::before {
        color: $primaryYellow;
      }
    }
  }
}
