.container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 47.8rem;
  padding-left: $gridSpacerMobile;
  padding-right: $gridSpacerMobile;

  @include tabletP {
    padding-left: $gridSpacerTabletP;
    padding-right: $gridSpacerTabletP;
    max-width: 76.8rem;
  }

  @include tabletL {
    padding-left: $gridSpacerTabletL;
    padding-right: $gridSpacerTabletL;
    max-width: 102.4rem;
  }

  @include desk {
    padding-left: 0;
    padding-right: 0;
    max-width: 122rem;
  }
  &--slider {
    width: auto;
    @include tabletP {
      max-width: 60.6rem;
      padding: 0;
    }
    @include tabletL {
      max-width: 99.4rem;
    }
    @include desk {
      max-width: 122rem;
    }
  }
}

.container-narrow {
  @include desk {
    max-width: 80.4rem;
    margin: 0 auto;
  }
}

.cols {
  display: flex;
  margin: 0 -0.9rem;
  &__col {
    flex-basis: 100%;
    padding: 0 0.9rem;
    @include desk {
      flex-basis: auto;
    }
    &--column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.narrow-section {
  @include tabletL {
    max-width: 50.5rem;
  }
  @include desk {
    max-width: 62.5rem;
  }
}
.grid {
  display: flex;
  flex-direction: column;
  @include tabletL {
    flex-direction: row;
  }
  &__sidebar {
    flex-grow: 0;
    flex-shrink: 0;
    @include tabletL {
      flex-basis: 27.3rem;
      padding-right: 3.1rem
    }
    @include desk {
      flex-basis: 31.3rem;
    }
  }
  &__content {
    flex-grow: 0;
    flex-shrink: 0;
    @include tabletL {
      flex-basis: calc(100% - 27.3rem);
    }
    @include desk {
      flex-basis: calc(100% - 31.3rem);
    }
  }
}
