.lecturer {
  &__img {
    border-radius: 50%;
    width: 20.2rem;
    height: 20.2rem;
    overflow: hidden;
    margin: 0 auto;
    @include desk {
      width: 24.5rem;
      height: 24.5rem;
      flex-shrink: 0;
    }
  }
  &__name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $primaryWhite;
    margin-top: 3rem;
    margin-bottom: 2.5rem;
    p {
      background-color: $primaryPurple;
      padding: 0.4rem 1rem;
    }
    h4 {
      background-color: $primaryPurple;
      padding: 1rem;
      margin-top: -0.4rem;
    }
  }
  .btn {
    margin-top: 2.4rem;
  }
  &--slider {
    @include desk {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    .lecturer {
      &__img {
        width: 24.5rem;
        height: 24.5rem;
        @include desk {
          width: 25rem;
          height: 25rem;
        }
      }
      &__content {
        @include desk {
          padding-left: 3rem;
        }
      }
      &__name {
        @include desk {
          margin-bottom: 2rem;
          margin-top: 0;
        }
      }
    }
    .btn {
      @include desk {
        margin-top: 2rem;
      }
    }
  }
}
