.section-slider {
  position: relative;
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: top center;
      object-position: top center;
      @include tabletL {
        -o-object-position: center center;
      object-position: center center;
      }
    }
  }
  &__content {
    padding: 7rem 0;
    text-align: center;
    position: relative;
    z-index: 1;
    @include tabletP {
      padding: 9rem 0;
    }
  }
  &__swiper {
    text-align: left;
    padding-bottom: 6.3rem;
    margin-bottom: 6rem;
    @include tabletP {
      padding-bottom: 6.6rem;
    }
    @include desk {
      padding-bottom: 6.4rem;
      margin-bottom: 7rem;
    }
    &--lecturers {
      @include desk {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }
      .swiper-pagination-bullets.swiper-pagination--with-nav {
        @include desk {
          max-width: 117.5rem;
        }
      }
      .swiper-buttons {
        @include desk {
          max-width: 117.5rem;
        }
      }
    }
    &--testimonials {
      .swiper-slide {
        height: auto;
      }
    }
    &--quotes {
      margin-bottom: 0;
      @include tabletL {
        max-width: 80.4rem;
      }
    }
    &--another {
      margin-bottom: 0;
    }
    &--trainings-online{
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      .swiper-buttons {
        left: 1.5rem;
        right: 1.5rem;
      }
      .swiper-slide {
        height: auto;
        .training {
          height: 100%;
        }
      }
      .swiper-pagination-bullets.swiper-pagination--with-nav {
        padding: 0 6.5rem;
        @include tabletP {
          padding: 0 6.8rem;
        }
      }
      &.swiper--with-fade {
        margin-bottom: 0;
        @include tabletP {
          padding-right: 33.6rem;
        }
        @include tabletL {
          padding-right: 17.6rem;
        }
        @include desk {
          padding-right: 25.6rem;
        }
      }
    }
    &--video {
      margin-bottom: 0;
    }
    &--no-nav {
      padding-bottom: 2.5rem;
    }
  }
  h2 {
    margin-bottom: 6rem;
    @include tabletP {
      margin-bottom: 6.5rem;
    }
    @include desk {
      margin-bottom: 7rem;
    }
  }
  &--lecturers {
    background-color: $lightBlue;
  }
  &--quotes {
    background-color: $lightBlue;
  }
  &--another {
    .section-slider__content {
      text-align: left;
    }
    h2 {
      margin-bottom: 3.5rem;
      color: $txtHeader;
    }
    h6 {
      margin-bottom: 5rem;
      color: $txtParagraph;
      @include tabletP {
        width: 80%;
        margin-bottom: 7rem;
      }
      @include desk {
        width: 49%;
      }
    }
  }
  &--trainings-online {
    .section-slider__content {
      padding: 0 0 1rem;
      @include tabletP {
        padding: 2.2rem 0 3rem;
      }
      @include desk {
        padding: 0.6rem 0 2.5rem;
      }
    }
    h2 {
      margin-bottom: 5rem;
    }
    .training .btn {
      width: auto;
    }
  }
  &--video {
    .section-slider__content {
      padding: 0 0 5rem;
      @include tabletP {
        padding: 7rem 0 0;
      }
      @include desk {
        padding: 9rem 0 0;
      }
    }
    h2 {
      margin-bottom: 4.5rem;
      @include tabletP {
        margin-bottom: 5rem;
      }
    }
  }
  &--training {
    .section-slider__content {
      padding: 0 0 2.5rem;
      @include tabletP {
        padding-bottom: 4.5rem;
      }
      @include desk {
        padding: 0.6rem 0 6.5rem;
      }
    }
    h2 {
      margin-bottom: 4.9rem;
    }
    .swiper--with-fade::after {
      display: none;
      @include tabletP {
        display: block;
      }
    }
    .training .btn {
      width: auto;
    }
  }
  &--training-gallery {
    h2 {
      margin-bottom: 5rem;
    }
    .section-slider {
      &__content {
        padding: 5rem 0 0;
        @include tabletP {
          padding: 7rem 0 0;
        }
        @include desk {
          padding: 9rem 0 0;
        }
      }
      &__swiper {
        padding-bottom: 5.3rem;
        @include tabletP {
          padding-bottom: 3.1rem;
        }
      }
    }
    .swiper--with-fade {
      @include tabletL {
        padding-right: 10rem;
      }
      @include desk {
        padding-right: 13rem;
      }
    }
  }
}
