.why-us {
  padding: 7rem 0;
  background: linear-gradient(81.05deg, #C51D5D -2.18%, #DD4026 117.68%);
  color: $primaryWhite;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include tabletP {
    padding: 9rem 0 2.9rem;
  }
  @include desk {
    padding: 9rem 0 2.1rem;
  }
  h6 {
    margin: 6rem auto 0;
    @include tabletP {
      width: 80%;
    }
    @include tabletL {
      width: 65%;
    }
    @include desk {
      width: 50%;
      margin: 3.5rem auto 0;
    }
  }
  &__items {
    margin-top: 6rem;
    list-style-type: none;
    @include tabletP {
      display: flex;
      flex-wrap: wrap;
      margin: 6rem -0.9rem 0;
    }
    @include desk {
      margin: 7rem auto 0;
      max-width: 112rem;
      justify-content: space-between;
    }
  }
  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    counter-increment: css-counter 1;
    @include tabletP {
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0 5rem;
      margin-bottom: 6.1rem;
    }
    @include desk {
      flex-basis: 53.5rem;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      margin-bottom: 6.9rem;
    }
    &::before {
      content: counter(css-counter);
      position: absolute;
      top: 20.1rem;
      left: 51%;
      transform: translateX(-50%);
      font-size: 20.6rem;
      font-weight: $fontWeightSemiBold;
      opacity: 0.1;
      @include tabletP {
        top: 19.7rem;
        left: 49%;
      }
      @include desk {
        top: 4.7rem;
        transform: none;
        left: 12.7rem;
      }
    }
    & + .why-us__item {
      margin-top: 6.1rem;
      @include tabletP {
        margin-top: 0;
      }
    }
  }
  &__item-content {
    z-index: 1;
    @include desk {
      max-width: 43rem;
      text-align: left;
      padding-left: 6rem;
    }
  }
  &__image {
    width: 10.5rem;
    height: 10.5rem;
    border-radius: 50%;
    overflow: hidden;
  }
  &__subtitle {
    margin-top: 3.9rem;
    padding: 0 0.3rem;
    @include desk {
      margin-top: 0;
      padding: 0;
      max-width: 32rem;
    }
  }
  &__description {
    margin-top: 2.5rem;
    @include desk {
      line-height: 2.1rem;
    }
  }
  &--information {
    padding: 8rem 0;
    .title {
      @include desk {
        text-align: left;
      }
    }
    .why-us {
      &__subtitle {
        padding: 0;
        margin-top: 5.9rem;
        @include desk {
          margin-top: 0;
          max-width: none;
        }
      }
      &__items {
        @include tabletP {
          display: block;
        }
      }
      &__item {
        @include tabletP {
          width: 34.2rem;
          margin: 0 auto;
          padding: 0;
        }
        @include desk {
          width: 100%;
        }
        &::before {
          content: '?';
          top: 22.5rem;
          font-size: 24.5rem;
          left: 52%;
          @include desk {
            top: 5.8rem;
            left: 16%;
          }
        }
        & + .why-us__item {
          margin-top: 10.1rem;
          @include desk {
            margin-top: 9.9rem;
          }
        }
      }
      &__item-content {
        @include desk {
          max-width: 69.9rem;
        }
        p {
          margin-top: 2.5rem;
        }
      }
    }
  }
  &--narrow {
    .title {
      @include tabletL {
        text-align: left;
      }
    }
    .why-us {
      &__items {
        @include tabletL {
          margin-top: 5rem;
        }
      }
      &__item {
        @include tabletL {
          flex-basis: 53.5rem;
          flex-direction: row;
          align-items: flex-start;
          padding: 0;
          margin-bottom: 6rem;
        }
        &::before {
          @include tabletL {
            top: 4.1rem;
            left: 15rem;
          }
          @include desk {
            left: 11rem;
          }
        }
      }
      &__item-content {
        @include tabletL {
          max-width: 39rem;
          text-align: left;
          padding-left: 6rem;
        }
        @include desk {
          max-width: 44rem;
        }
      }
      &__image {
        @include tabletL {
          width: 7.8rem;
          height: 7.8rem;
        }
      }
      &__subtitle {
        @include tabletL {
          margin-top: 0;
          padding: 0;
          max-width: 32rem;
        }
      }
      &__description {
        @include desk {
          margin-top: 2.1rem;
        }
      }
    }
  }
  & + .section-slider--training {
    padding-top: 5rem;
    @include tabletP {
        padding-top: 7rem;
    }
    @include desk {
        padding-top: 9rem;
    }
  }
}
