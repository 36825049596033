html {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  font-size: 62.5%;
  font-style: normal;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

// inherited from <html>
*,
*::before,
*::after {
  box-sizing: inherit;
}

*::selection {
  background: $primaryBorder;
}

body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-size-adjust: 100%; // iOS on orientation change
  color: $primaryBlack;
  font-family: $fontFamilyPrimary;
  font-weight: $fontWeightRegular;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-style: normal;
  font-stretch: normal;
  backface-visibility: hidden;
  background: $primaryWhite;
  font-size: 1.4rem;
  line-height: 2.3rem;
}
.body-modal {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 14;
    background: rgba(3, 3, 3, 0.3);
    opacity: 0;
    pointer-events: none;
    transition: $transition-base;
  }
  &--active {
    &::before {
      opacity: 1;
      pointer-events: all;
    }
  }
}

main {
  position: relative;
  flex:1;
  margin-top: 8.66rem;
  @include tabletL {
    margin-top: 9.2rem;
  }
}

a,
label,
.button {
  -webkit-tap-highlight-color: transparent;
  transition: $transition-base;
}

button {
  font-family: $fontFamilyPrimary;
  transition: $transition-base;
}

img, picture {
  display: block;
  max-width: 100%;
  height: auto;
}

picture {
  source, img {
    object-fit: cover;
    height: 100%;
    color: $primaryBlack;
  }
}

// placeholders
input,
textarea,
select {
  &::-webkit-input-placeholder {
    color: $txtFormEpt;
  }

  &::-moz-placeholder {
    opacity: 1;
    color: $txtFormEpt;
  }

  &:-ms-input-placeholder {
    color: $txtFormEpt;
  }
}
