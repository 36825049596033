.information {
  padding: 7rem 0;
  overflow: hidden;
  @include tabletP {
    padding: 9rem 0;
  }
  &__item {
    position: relative;
    @include tabletL {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4rem;
      &:nth-child(odd) {
        flex-direction: row-reverse;
      }
    }
    @include desk {
      gap: 0;
    }
    &::before {
      position: absolute;
      content: "";
      bottom: -11.2rem;
      left: -32.9rem;
      width: 57.2rem;
      height: 57.2rem;
      border-radius: 50%;
      border: 9.9rem solid $lightGreen;
      z-index: -1;
      @include tabletP {
        bottom: 1.6rem;
        left: -32rem;
        width: 66.6rem;
        height: 66.6rem;
        border: 11.5rem solid $lightGreen;
      }
      @include tabletL {
        bottom: 1.6rem;
        left: 55rem;
      }
      @include desk {
        bottom: 4.6rem;
        left: 67rem;
        width: 102.9rem;
        height: 102.9rem;
        border: 18rem solid $lightGreen;
      }
    }
    &:nth-child(even) {
      &::before {
        left: 7rem;
        bottom: -11.3rem;
        @include tabletP {
          left: 34.5rem;
          bottom: 1.9rem;
        }
        @include tabletL {
          left: -23.5rem;
          bottom: -41.1rem;
        }
        @include desk {
          left: -47.7rem;
          bottom: -72.5rem;
        }
      }
    }
    & + .information__item {
      margin-top: 8.8rem;
    }
  }
  &__video {
    position: relative;
  }
  &__image {
    height: 19.5rem;
    box-shadow: $navBoxShadow;
    @include tabletP {
      height: 42.3rem;
    }
    @include tabletL {
      height: 27.2rem;
      width: 46.4rem;
    }
    @include desk {
      height: 35.5rem;
      width: 59.5rem;
    }
    img {
      object-fit: cover;
      object-position: center center;
    }
  }
  &__text {
    text-align: center;
    margin-top: 6.1rem;
    @include tabletP {
      text-align: left;
      max-width: 53rem;
    }
    @include tabletL {
      margin-top: 0;
      max-width: 45rem;
    }
    @include desk {
      max-width: 49rem;
    }
    h2 {
      @include tabletL {
        max-width: 47rem;
      }
    }
    h6 {
      margin-top: 3rem;
      @include tabletL {
        margin-top: 3.6rem;
      }
    }
    a {
      margin-top: 3.9rem;
      @include tabletP {
        margin-top: 5rem;
      }
      @include tabletL {
        margin-top: 3.5rem;
      }
    }
  }
}
