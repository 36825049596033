.btn {
  position: relative;
  font-size: 1.4rem;
  font-weight: $fontWeightBold;
  line-height: 1.9rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: $primaryWhite;
  border-radius: 4rem;
  padding: 1.3rem 5rem;
  z-index: 1;
  transition: $transition-base;
  background: $primaryOrange;
  border: 0.2rem solid $primaryOrange;
  box-shadow: 5px 12px 22px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  text-align: center;
  &:hover {
    background: $primaryYellow;
    border-color: $primaryYellow;
  }
  i {
    margin-right: 0.8rem;
    font-size: 1.5rem;
    position: relative;
    top: 0.3rem;
    &::before {
      color: $primaryWhite;
      transition: $transition-base;
    }
  }
  &--white {
    background: $primaryWhite;
    border: 0.2rem solid $primaryOrange;
    color: $primaryOrange;
    box-shadow: none;
    &:hover {
      color: $primaryWhite;
      i {
        &::before {
          color: $primaryWhite;
        }
      }
    }
    i {
      &::before {
        color: $primaryOrange;
      }
    }
  }
  &--full-width {
    display: flex;
    width: 100%;
  }
  &--link {
    padding: 0;
    background: transparent;
    border-radius: 0;
    border: none;
    box-shadow: none;
    color: $primaryOrange;
    &:hover {
      background: transparent;
      color: $primaryYellow;
      i {
        &::before {
          color: $primaryYellow;
        }
      }
    }
    i {
      margin-left: 1.5rem;
      margin-right: 0;
      font-size: 0.8rem;
      top: 0;
      &.icon-arrow-big--left {
        transform: rotate(180deg);
        margin-right: 1.5rem;
        margin-left: 0;
      }
      &::before {
        color: $primaryOrange;
      }
    }
  }
  &--play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    .icon-play {
      font-size: 6.1rem;
      @include tabletP {
        font-size: 11.1rem;
      }
      .path1 {
        &::before {
          transition: $transition-base;
        }
      }
      &:hover {
        .path1 {
          &::before {
            color: $primaryYellow;
          }
        }
      }
    }
  }
  &--clear {
    .icon-close-small {
      margin-right: 0.7rem;
      font-size: 1.1rem;
      top: 0;
      &--right {
        margin-right: 0;
        margin-left: 0.7rem;
      }
    }
  }
  &--pagination {
    font-size: 1.6rem;
    line-height: 1.8rem;
    padding: 1.3rem 2rem;
    background-color: $primaryWhite;
    color: $txtHeader;
    box-shadow: none;
    &:hover {
      color: $primaryWhite;
    }
  }
  &--hide {
    display: none;
  }
  &--disabled {
    color: $txtFormEpt;
    pointer-events: none;
    i::before {
      color: $txtFormEpt;
    }
  }
}
