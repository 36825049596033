.banner-slider {
  position: relative;
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: top center;
      object-position: top center;
      @include tabletL {
        -o-object-position: center center;
      object-position: center center;
      }
    }
  }
  &__text {
    padding: 7rem 0 69.2rem;
    text-align: center;
    color: $primaryWhite;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @include tabletP {
      padding: 9rem 0 71.9rem;
      text-align: left;
    }
    @include tabletL {
      padding: 9rem 0 15.5rem;
      width: 50%;
      margin-left: 0;
      min-height: 42rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .h1 {
      margin: 3.2rem auto 0;
      width: 95%;
      @include tabletP {
        width: 75%;
        margin-left: 0;
      }
      @include tabletL {
        margin-top: 3rem;
        width: 100%;
      }
      @include desk {
        width: 75%;
      }
    }
    h5 {
      margin-top: 1.3rem;
      @include tabletL {
        margin-top: 1.6rem;
      }
    }
    .btn {
      margin-top: 4rem;
    }
  }
  &__swiper-pagination {
    &.swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 62rem;
      @include tabletP {
        bottom: 64.7rem;
        max-width: 61.8rem;
        margin: 0 auto;
        text-align: left;
        right: 0;
      }
      @include tabletL {
        max-width: 100.4rem;
        bottom: 14.5rem;
      }
      @include desk {
        max-width: 123rem;
      }
    }
  }
}
