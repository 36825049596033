.counter {
  position: relative;
  display: inline-table;
  &__img {
    width: 13rem;
    height: 13rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    @include tabletP {
      margin: 0;
    }
    @include desk {
      width: 19rem;
      height: 19rem;
    }
  }
  &__content {
    text-align: center;
    color: $primaryBlue;
    @include tabletP {
      margin: -3rem 0 0 5.5rem;
      text-align: left;
    }
    @include desk {
      margin: -4rem 0 0 10rem;
    }
    h6 {
      text-align: center;
      font-weight: $fontWeightSemiBold;
      margin: 0.7rem auto 0;
      max-width: 14.5rem;
      @include tabletP {
        text-align: left;
        margin: 0.7rem 0 0;
      }
    }
  }
  &__figure {
    display: flex;
    align-items: flex-start;
  }
  &__number {
    font-size: 7.5rem;
    line-height: 7.5rem;
    font-weight: $fontWeightBold;
    @include desk {
      font-size: 9.8rem;
      line-height: 9rem;
    }
  }
  &__char {
    font-size: 4.6rem;
    line-height: 4.8rem;
    font-weight: $fontWeightBold;
    @include desk {
      font-size: 6rem;
      line-height: 5.3rem;
    }
  }
}
